import * as React from 'react'
import {
  Table,
  Form,
  Button,
  Pagination,
  Modal,
  message,
  Popconfirm,
  Input,
  Select,
  Radio,
} from 'antd'
import BreadcrumbCustom from '../BreadcrumbCustom'
import Main from '../main'
import Group from '../group/group'
import { GroupType } from './../../utils/index'
import GroupSel from './../widget/GroupSel'
import StudentAdd from './StudentAdd'
import { studentList, studentDel, studentImp, studentAudit } from './../../service/student'
import { moveToGroup, download } from './../../service/index'
import DocUploader from './../widget/DocUploader'
import { FormComponentProps } from 'antd/lib/form'
import { STU_EXP } from './../../service/config'
import AwardCerts from './AwardCerts'
import RegionCascader from './../widget/RegionCascader'
import { PERM, hasPermits } from './../../utils/permits'

const { Column } = Table
const { Option } = Select

export interface IStudentProps extends FormComponentProps {}
interface IStudentStates {
  dataSource: any
  pageSize: number
  groupId: any
  total: number
  page: number
  checkedList: any
  editStudentId: any
  loading: boolean
  audit: any
  showAuditReason: Boolean
  region: any
  regiProvinId: any
  regiCityId: any
  provinName: any
  cityName: any
  regiCountrId: any
  countrName: any
}

class Student extends React.Component<IStudentProps, IStudentStates> {
  constructor(props: any) {
    super(props)
    this.getStuList = this.getStuList.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onGroupSelect = this.onGroupSelect.bind(this)
    this.onChangeGroup = this.onChangeGroup.bind(this)
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this)
    this.onAuditConfirm = this.onAuditConfirm.bind(this)
    this.state = {
      pageSize: 10,
      dataSource: [],
      groupId: '',
      total: 0,
      page: 1,
      checkedList: [],
      editStudentId: '',
      loading: false,
      audit: {
        cause: '',
        auditStatus: 1,
      },
      showAuditReason: false,
      region: {},
      regiProvinId: '',
      regiCityId: '',
      provinName: '',
      cityName: '',
      regiCountrId: '',
      countrName: '',
    }
  }
  componentDidMount() {
    this.getStuList()
  }

  showTotal(total: number) {
    return `共 ${total} 条`
  }

  getStuList = async () => {
    const values = this.props.form!.getFieldsValue()
    this.setState({
      loading: true,
    })
    const valueData = { ...values, region: '' }
    const params = {
      page: this.state.page,
      rows: this.state.pageSize,
      groupId: this.state.groupId,
      ...valueData,
      regiProvinId: this.state.regiProvinId,
      regiCityId: this.state.regiCityId,
      regiCountrId: this.state.regiCountrId,
    }
    const res = await studentList(params)
    this.setState({
      dataSource: res.data,
      total: res.total,
      loading: false,
    })
  }

  handleSearch = () => {
    setTimeout(() => {
      this.setState({
        page: 1,
      })
      this.getStuList()
    }, 0)
  }

  onPageChange(page: any) {
    setTimeout(() => {
      this.setState({
        page: page,
      })
      this.getStuList()
    }, 0)
  }

  onGroupSelect = (groups: any) => {
    const group = groups[0]
    setTimeout(() => {
      if (group) {
        this.setState({
          groupId: group.groupId,
          page: 1,
        })
      } else {
        this.setState({
          groupId: '',
          page: 1,
        })
      }
      this.getStuList()
    }, 0)
  }
  onChangeGroup = async (group: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请选择学员')
      return
    }
    const params = { groupId: group.groupId, serialIds: this.state.checkedList.join(',') }
    const res = await moveToGroup(params)
    if (res.success) {
      message.success('分组移动成功')
      setTimeout(() => {
        this.setState({ page: 1, checkedList: [] })
        this.getStuList()
      }, 0)
    } else {
      message.error('分组移动失败')
    }
  }
  onDeleteConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择账号')
      return
    }
    const res = await studentDel(this.state.checkedList)
    if (res.success) {
      message.success('删除成功')
      this.getStuList()
    } else {
      message.error('删除失败')
    }
  }

  onDeleteCancel = (e: any) => {}

  onAuditConfirm = async (e: any) => {
    if (this.state.checkedList.length == 0) {
      message.warn('请先选择账号')
      return
    }
    if (this.state.audit.auditStatus == 2 && !this.state.audit.cause) {
      message.warn('请填写不通过原因')
      return
    }
    const params = {
      studentIds: this.state.checkedList.join(','),
      ...this.state.audit,
    }
    const res = await studentAudit(params)
    if (res.success) {
      message.success('审核成功')
      this.getStuList()
    } else {
      message.error('审核失败')
    }
  }

  onAuditCancel = (e: any) => {}

  handleUploadFileChange = async (e: any) => {
    const res = await studentImp(e)
    if (res.success) {
      message.success('导入成功')
      this.handleSearch()
    } else {
      let str = res.msg + '，'
      res.data.forEach((item: any) => {
        str += `(第${item.rowNum}行, 【${item.column}】 列)\n`
      })
      message.warn(str)
      this.handleSearch()
    }
  }

  handleQuesExport = async (e: any) => {
    const values = this.props.form!.getFieldsValue()
    const params = {
      ...values,
      groupId: this.state.groupId,
      studentIds: this.state.checkedList.join(','),
    }
    await download(STU_EXP, '学员', params)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const rowSelection = {
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        console.log(selectedRowKeys)
        this.setState({
          checkedList: selectedRowKeys,
        })
      },
      getCheckboxProps: (record: any) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      }),
    }

    return (
      <div className="w-full h-full">
        <BreadcrumbCustom first="学员管理" second="学员列表" />
        <Main
          leftTitle="学员分类列表"
          contentTitle="所有学员"
          leftContent={<Group type={GroupType.STU} onSelect={this.onGroupSelect} disableEdit={true}/>}
          centerContent={
            <div>
              <Form layout="inline" style={{ marginBottom: 16 }}>
                {hasPermits(PERM.stu_func_add) && (
                  <Form.Item>
                    <StudentAdd
                      icon="plus"
                      buttonTitle="添加考生"
                      onSuccess={() => {
                        this.setState({
                          page: 1,
                        })
                        this.getStuList()
                      }}
                    />
                  </Form.Item>
                )}
                {hasPermits(PERM.stu_func_imp) && (
                  <Form.Item>
                    <DocUploader
                      buttonTitle="导入学员"
                      supportTypes="Excel"
                      handleUploadFileChange={this.handleUploadFileChange}
                    />
                    <Button icon="export" type="primary" ghost onClick={this.handleQuesExport}>
                      导出所选学员({this.state.checkedList.length})
                    </Button>
                  </Form.Item>
                )}
                {hasPermits(PERM.stu_func_groupmod) && (
                  <Form.Item>
                    <GroupSel
                      title="移动到分类"
                      buttonTitle="移动到分类"
                      type={GroupType.STU}
                      hideInput={true}
                      onChange={this.onChangeGroup}
                    />
                  </Form.Item>
                )}
                {hasPermits(PERM.stu_func_audit) && (
                  <Form.Item>
                    <Popconfirm
                      title={
                        <div>
                          <div>审核</div>
                          <div className="mt-m">
                            <Radio.Group
                              value={this.state.audit.auditStatus}
                              onChange={(e) => {
                                const audit = this.state.audit
                                audit.auditStatus = e.target.value
                                if (e.target.value == 1) {
                                  audit.cause = ''
                                }
                                this.setState({
                                  audit: audit,
                                  showAuditReason: e.target.value == 2,
                                })
                              }}
                            >
                              <Radio value={1} key={'1'}>
                                审核通过
                              </Radio>
                              <Radio value={2} key={'2'}>
                                审核不通过
                              </Radio>
                            </Radio.Group>
                          </div>
                          {this.state.showAuditReason && (
                            <div className="mt-s">
                              <Input
                                placeholder="原因"
                                allowClear
                                onChange={(e) => {
                                  const audit = this.state.audit
                                  audit.cause = e.target.value
                                  this.setState({
                                    audit: audit,
                                  })
                                }}
                              />
                            </div>
                          )}
                        </div>
                      }
                      onConfirm={this.onAuditConfirm}
                      onCancel={this.onAuditCancel}
                      okText="审核"
                      cancelText="取消"
                    >
                      <Button icon="check" type="primary" ghost>
                        审核
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
                {hasPermits(PERM.stu_func_del) && (
                  <Form.Item>
                    <Popconfirm
                      title="确定要删除学员吗"
                      onConfirm={this.onDeleteConfirm}
                      onCancel={this.onDeleteCancel}
                      okText="删除"
                      cancelText="取消"
                    >
                      <Button icon="delete" type="danger" ghost>
                        删除
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
              </Form>
              {hasPermits(PERM.stu_func_search) && (
                <Form layout="inline" style={{ marginBottom: 16 }} onSubmit={this.handleSearch}>
                  <Form.Item label="所属区域">
                    {getFieldDecorator('region')(
                      <RegionCascader
                        onChange={(e: any) => {
                          this.setState({
                            regiProvinId: '',
                            provinName: '',
                            regiCityId: '',
                            cityName: '',
                            regiCountrId: '',
                            countrName: '',
                          })
                          if (e.length > 0) {
                            e.forEach((element: any) => {
                              setTimeout(() => {
                                if (element.level == 1) {
                                  this.setState({
                                    regiProvinId: element.id,
                                    provinName: element.name,
                                  })
                                } else if (element.level == 2) {
                                  this.setState({
                                    regiCityId: element.id,
                                    cityName: element.name,
                                  })
                                } else if (element.level == 3) {
                                  this.setState({
                                    regiCountrId: element.id,
                                    countrName: element.name,
                                  })
                                }
                              }, 0)
                            })
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="姓名">
                    {getFieldDecorator('stuName')(<Input placeholder="姓名" allowClear />)}
                  </Form.Item>
                  <Form.Item label="手机">
                    {getFieldDecorator('phone')(<Input placeholder="手机" allowClear />)}
                  </Form.Item>
                  <Form.Item label="身份证">
                    {getFieldDecorator('cardID')(<Input placeholder="身份证" allowClear />)}
                  </Form.Item>
                  <Form.Item label="企业名称">
                    {getFieldDecorator('compName')(<Input placeholder="企业名称" allowClear />)}
                  </Form.Item>
                  <Form.Item label="状态">
                    {getFieldDecorator('auditStatus')(
                      <Select allowClear style={{ width: '80px' }}>
                        <Option key="0">未审核</Option>
                        <Option key="1">已审核</Option>
                        <Option key="2">未通过</Option>
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="是否有证书">
                    {getFieldDecorator('isCcie')(
                      <Select allowClear style={{ width: '80px' }}>
                        <Option key="1">是</Option>
                        <Option key="0">否</Option>
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" icon="search" htmlType="submit">
                      搜索
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <Pagination
                size="small"
                current={this.state.page}
                pageSizeOptions={['10', '20', '50', '100', '200']}
                pageSize={this.state.pageSize}
                showSizeChanger
                showQuickJumper
                total={this.state.total}
                showTotal={this.showTotal}
                onChange={this.onPageChange}
                onShowSizeChange={(current, size) => {
                  setTimeout(() => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  }, 0)
                }}
              />
              <Table
                rowSelection={rowSelection}
                rowKey="studentId"
                loading={this.state.loading}
                pagination={{
                  current: this.state.page,
                  onChange: this.onPageChange,
                  pageSize: this.state.pageSize,
                  size: 'small',
                  total: this.state.total,
                  showTotal: this.showTotal,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '20', '50', '100', '200'],
                  onShowSizeChange: (current, size) => {
                    setTimeout(() => {
                      this.setState({
                        pageSize: size,
                      })
                      this.onPageChange(1)
                    }, 0)
                  },
                }}
                dataSource={this.state.dataSource}
              >
                <Column
                  title="姓名"
                  dataIndex="stuName"
                  key="stuName"
                  render={(stuName: any) => stuName}
                />
                <Column
                  title="密码"
                  dataIndex="pwd"
                  key="pwd"
                  render={(pwd: any) => pwd}
                />
                <Column
                  title="性别"
                  dataIndex="gender"
                  key="gender"
                  render={(gender: any) => gender}
                />
                <Column
                  title="手机"
                  dataIndex="phone"
                  key="phone"
                  render={(phone: any) => phone}
                />
                <Column
                  title="身份证"
                  dataIndex="cardID"
                  key="cardID"
                  render={(cardID: any) => cardID}
                />
                <Column
                  title="企业"
                  dataIndex="compName"
                  key="compName"
                  render={(compName: any) => compName}
                />
                <Column
                  title="所在组"
                  dataIndex="groupNames"
                  key="groupNames"
                  render={(groupNames: any) => groupNames}
                />
                <Column
                  title="状态"
                  dataIndex="auditStatus"
                  key="auditStatus"
                  render={(auditStatus: any) => {
                    let auditStatusStr = ''
                    switch (parseInt(auditStatus)) {
                      case 1: {
                        auditStatusStr = '审核通过'
                        break
                      }
                      case 2: {
                        auditStatusStr = '审核未通过'
                        break
                      }
                      default: {
                        auditStatusStr = '未审核'
                      }
                    }
                    return auditStatusStr
                  }}
                />
                <Column
                  title="是否有证书"
                  dataIndex="isCcie"
                  key="isCcie"
                  render={(isCcie: any) => (isCcie == 1 ? '是' : '否')}
                />
                <Column
                  title="操作"
                  dataIndex="studentId"
                  width={250}
                  key="studentId"
                  render={(studentId: any, student: any) => (
                    <div className="flex-h">
                      {hasPermits(PERM.stu_func_mod) && (
                        <StudentAdd
                          icon="edit"
                          buttonTitle="编辑"
                          studentId={studentId}
                          onSuccess={() => {
                            setTimeout(() => {
                              this.setState({
                                page: 1,
                              })
                              this.getStuList()
                            }, 0)
                          }}
                        />
                      )}
                      {hasPermits(PERM.stu_func_certs) && !student.isCcie && (
                        <AwardCerts
                          student={student}
                          onSuccess={() => {
                            setTimeout(() => {
                              this.setState({
                                page: 1,
                              })
                              this.getStuList()
                            }, 0)
                          }}
                        />
                      )}
                    </div>
                  )}
                />
              </Table>
            </div>
          }
        />
      </div>
    )
  }
}

export default Form.create<IStudentProps>()(Student)
